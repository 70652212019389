<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-button type="primary" round icon="el-icon-back" size="mini"
										@click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
									<span class="tct_tit">{{$t('i18nn_4f1444e2d3ca67a2')}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<div class="filterCon" style="">
									<ul class="filterConList">
										<li>
											<span>{{$t('i18nn_54672b9140a3afe0')}}</span>
											<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable clearable
												:disabled="print_loading" style="margin-left: 10px;" @change="changePrinter">
												<el-option v-for="(item, index) in printerList" :key="index" :label="item.name"
													:value="item.value"></el-option>
											</el-select>
										</li>
										<!-- <li>
											<span>{{$t('i18nn_a85af4ba66084d28')}}</span>
											<el-select filterable v-model="printerSize"
												:placeholder="$t('2ad108ab2c560530')" style="width: 100px;">
												<el-option v-for="(item, index) in sizeList" :key="index"
													:label="item.label" :value="item.value"></el-option>
											</el-select>
										</li> -->
									</ul>
								</div>
							</el-col>
						</el-row>
					</div>

					<div>
						<el-card style="">
							<div style="margin-bottom:10px;">
								<el-radio-group v-model="scanType">
									<el-radio :label="'2'">{{$t('i18nn_7f6c721b57ed0e7b')}}</el-radio>
									<el-radio :label="'1'">{{$t('a2df50399ce1bf10')}}</el-radio>

								</el-radio-group>
							</div>

							<div v-if="'1'==scanType">
								<el-input ref="sendNo" type="text" v-model="filterData.sendNo" maxlength="50" clearable
									:placeholder="$t('i18nn_33ba2cabce60048d')" @keyup.enter.native="getPrintInfoByBatchAction()"
									style="width: 800px;">
									<template slot="prepend">
										<i class="el-icon-notebook-2"></i>
										<span>{{$t('a2df50399ce1bf10')}}</span>
									</template>
									<el-button slot="append" type="warning" icon="el-icon-search"
										@click="getPrintInfoByBatchAction()">{{$t('1e7246dd6ccc5539')}}</el-button>
								</el-input>

								<el-button type="primary" plain @click="clearsendNo()" icon="el-icon-circle-close"
									style="margin-left:10px;">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>

							</div>

							<div v-else-if="'2'==scanType">
								<el-input ref="sysOrderNo" type="text" v-model="filterData.sysOrderNo" maxlength="50" clearable
									:placeholder="$t('i18nn_cc546e1ccd13cf0b')" @keyup.enter.native="getPrintInfoByBatchAction()"
									style="width: 800px;">
									<template slot="prepend">
										<i class="el-icon-notebook-2"></i>
										<span>{{$t('i18nn_7f6c721b57ed0e7b')}}</span>
									</template>
									<el-button slot="append" type="warning" icon="el-icon-search"
										@click="getPrintInfoByBatchAction()">{{$t('1e7246dd6ccc5539')}}</el-button>
								</el-input>

								<el-button type="primary" plain @click="clearsendNo()" icon="el-icon-circle-close"
									style="margin-left:10px;">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>

							</div>
						</el-card>

						<div>
							<div style="padding: 10px 0; ">
								<!-- <el-radio-group v-model="selFileTypes" size="medium" style="margin-bottom: 10px;" @change="fileTypeChange">
									<el-radio border v-for="item in selectOption.wh_file_type" :key="item.code" :label="item.code">
										{{ $Utils.i18nCodeText(item) }}
									</el-radio>
								</el-radio-group> -->
								<ul style="font-size: 16px;">
									<li style="margin: 20px;">
										<span>自动打印</span>:
										<el-switch v-model="isAuto" active-text="开启" inactive-text="关闭">
										</el-switch>
									</li>

									<li style="margin: 20px;display: flex; flex-wrap: wrap;">
										<span>打印类型</span>:
										<el-checkbox-group v-model="selFileTypes" @change="handleCheckedFileTypesChange">
											<el-checkbox v-for="item in selectOption.wh_file_type" :key="item.code"
												:label="item.code">{{ $Utils.i18nCodeText(item)}}</el-checkbox>
										</el-checkbox-group>
									</li>
									<li style="margin: 20px;">
										<span>打印数量</span>:
										<el-input-number v-model="printNum" :min="1" :max="100" label=""></el-input-number>
										<el-button @click="printStart()" type="primary" icon="el-icon-printer"
											style="margin-left: 20px;">手动打印</el-button>
									</li>
								</ul>

							</div>
						</div>


						<div style="">
							<el-card shadow="" class="" style="">
								<div slot="header">
									<span>{{$t('f5d43732e3f6cf4d')}}</span>
									<span
										class="red"><span>{{$t('i18nn_63b637700eeaf5a3')}}</span>pdf<span>{{$t('i18nn_9e8e8134d43fee59')}}</span></span>
								</div>
								<!-- <div style="margin-bottom: 10px;" v-if="currentData.attachments && currentData.attachments.length>0">
									<printFileBtn :fileList="multipleSelection"></printFileBtn>
								</div> -->
								<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
									size="small" @selection-change="handleSelectionChange" :max-height="$store.state.tableMaxHeight4">
									<el-table-column type="selection" fixed="left" align="center" width="50"></el-table-column>
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('7b1c2b1adc920d9c')"></el-table-column>
									<el-table-column prop="typeName" :label="$t('184333c81babf2f1')" width="100">
									</el-table-column>
									<el-table-column prop="printCount" :label="'打印次数'" width="100">
										<template slot-scope="scope">
											<div>
												<strong v-if="0!=scope.row.printCount">
													{{scope.row.printCount}}
												</strong>
												<span v-else>
													{{scope.row.printCount}}
												</span>
											</div>
										</template>
									</el-table-column>
									
									<el-table-column :label="$t('2674282277c3714e')" width="130">
										<template slot-scope="scope">
											<el-image style="width: 50px; height: 50px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
												:preview-src-list="currentData.attachments.map(itemPre=> { return itemPre.url})">
												<div slot="error" class="image-slot">
													<i class="el-icon-document"></i>
												</div>
											</el-image>
										</template>
									</el-table-column>
									<el-table-column prop="fileName" :label="$t('1aa9b398f37906a4')">
									</el-table-column>
									<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
										<template slot-scope="scope">
											<a :href="scope.row.url" target="_blank">{{scope.row.url}}</a>
										</template>
									</el-table-column>
								</el-table>
							</el-card>

							<el-card shadow="never" class="" style="margin-bottom: 10px; flex:1;" :body-style="{'padding':'10px'}">
								<div slot="header">
									<span>{{$t('i18nn_74cb497a2bc3955f')}}</span>
								</div>
								<div class="scanCodeList">
									<div style="margin-bottom: 10px;">
										<div class="">
											<el-descriptions title="" :size="''" border style="margin-bottom: 5px;">

												<el-descriptions-item :label="$t('ae61193590bd513d')">
													<strong style="color:#4169E1;font-size:18px;">{{currentData.sendNo}}</strong>
												</el-descriptions-item>
												<el-descriptions-item :label="$t('i18nn_7f6c721b57ed0e7b')">
													<strong style="color:#800000;font-size:18px;">{{currentData.sysOrderNo}}</strong>
												</el-descriptions-item>
											</el-descriptions>
										</div>
										<div style="margin-bottom: 10px;">
											<el-descriptions title="" :size="''" border>
												<el-descriptions-item :label="$t('i18nn_53f8f97f65af0a5e')">
													{{currentData.carTmsTypeName}}
												</el-descriptions-item>
												<el-descriptions-item label="FBA">{{currentData.isFbaName}}
												</el-descriptions-item>
												<el-descriptions-item label="FBA仓库">{{currentData.toWhNo}}
												</el-descriptions-item>
												<el-descriptions-item :label="$t('i18nn_31519f8b1453a53e')">
													{{currentData.sendOutTypeName}}
												</el-descriptions-item>
												<el-descriptions-item :label="$t('i18nn_18c1c1a3ea76e9d3')">{{currentData.palletCount}}
												</el-descriptions-item>
												<el-descriptions-item :label="$t('i18nn_f88b93f4fd02974c')">
													{{currentData.palletLabel}}
												</el-descriptions-item>
												<el-descriptions-item :label="$t('15b3627faddccb1d')">
													<div class="pre-text">{{currentData.remark}}</div>
												</el-descriptions-item>
											</el-descriptions>
										</div>
									</div>
								</div>
							</el-card>


						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <el-drawer :wrapperClosable="false" title="扫描的SKU" :visible.sync="drawerScanSku" :direction="'rtl'">
			<div>
				<div>
					<el-card shadow="never" class="" style="margin-bottom: 10px;" :body-style="{'padding':'10px'}">
						<div slot="header">
							<span>当前扫描的SKU</span>
						</div>
						<ul class="scanCodeList">
							<li v-for="(item,index) in scanList" :key="index">
								<el-tag v-if="index==0" :type="'primary'" effect="plain">
									{{ item.scanCode }}
								</el-tag>
							</li>
						</ul>
					</el-card>
					<el-card shadow="never" class="scanRiCon" :body-style="{'padding':'10px'}"
						:style="{'height':$store.state.tableMaxHeight10+'px'}">
						<div slot="header">
							<span>历史扫描的SKU</span>
						</div>
						<ul class="scanCodeList">
							<li v-for="(item,index) in scanList" :key="index">
								<el-tag v-if="index!=0" :type="'info'" effect="plain">
									{{ item.scanCode }}
								</el-tag>
							</li>
						</ul>
					</el-card>
				</div>
			</div>
		</el-drawer> -->

		<!--查看已打未打-->
		<!-- <BatchScanPrintDetList :openTime="ScanPrintDetOpenTime" :sendNo="filterData.sendNo"></BatchScanPrintDetList> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	// import BatchScanPrintDetList from '@/components/WarehouseCenter2/DropShipping/BatchScanPrintDetList.vue';
	import {
		pritePdfOrImgUrl,
		// priterPdfBase64,
		GetPrinterList,
		savePrtVal,
		getPrtVal
	} from '@/utils/LodopPrinter.js';
	import {
		getFileType
	} from '@/utils/utils.js';
	// import printFileBtn from '@/components/WarehouseCenter2/components/printFileBtn.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },

		components: {
			// BatchScanPrintDetList
			// printFileBtn
		},
		data() {
			return {

				// drawerScanSku: false,

				loading_load: false,

				// tabActiveName: "first",

				// activeName: 'first',
				// loading_det_load: false,

				// loading_count: false,
				// countData: {},
				printBatchInfo: {},
				//表格数据
				//loading,表格数据
				loading: false,

				scanType: '2',
				//表格数据
				tableData: [],
				
				currentData: {},

				print_loading: false,

				multipleSelection: [],

				isAuto: false, //是否自动打印
				printNum: 1,
				selFileTypes: [],

				// scanList: [],

				// ScanPrintDetOpenTime:'',
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 500],
				// 	page_size: 500,
				// 	total: 0
				// },
				// countPdfLabel: 0,
				// skuQuantity: 0,

				printerVal: "",
				// printerSize: "1",
				printerList: [],
				// sizeList: [{
				// 		value: '1',
				// 		label: '4in*适应'
				// 	},
				// 	{
				// 		value: '2',
				// 		label: '4in*6in缩小'
				// 	},
				// 	{
				// 		value: '3',
				// 		label: '自适应'
				// 	}
				// ],
				selectOption: {
					wh_file_type: []
				},
				// selectOption: {
				// 	wh_no: [],
				// },
				// isShowsendNoInput: true,
				// issendNoData: false,
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					sendNo: '',
					sysOrderNo: '',

					goodsSku: '',

				}
			};
		},
		// watch: {
		// 	$route(to, from) {
		// 		console.log('WhDropShipping $route', to, from);
		// 	}
		// },
		activated() {
			this.init();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			getDicData(['wh_file_type'],
				(data) => {
					this.selectOption.wh_file_type = data['wh_file_type'];
					//全部
					this.selFileTypes = this.selectOption.wh_file_type.map(item => item.code)
				});
		},
		methods: {
			init() {
				this.clearFilter();
				//自动获取焦点
				this.$nextTick(() => {
					if (!this.filterData.sendNo) {
						if (this.$refs.sendNo && '1' == this.scanType) {
							this.$refs.sendNo.focus();
						}
						if (this.$refs.sysOrderNo && '2' == this.scanType) {
							this.$refs.sysOrderNo.focus();
						}
					} else {

					}
				})
				// this.tableData = [];
				// this.scanList = [];
				this.currentData = {};
				this.tableData = [];
				this.multipleSelection = [];
				this.initPriterList();
				//设置上次打印机的值
				// this.printerVal = getPrtVal();

			},
			//清空发货编号
			clearsendNo() {
				this.filterData.sendNo = "";
				this.filterData.sysOrderNo = "";

				this.currentData = {};
				this.tableData = [];
				this.multipleSelection = [];
				//自动获取焦点
				this.$nextTick(() => {
					if (this.$refs.sendNo && '1' == this.scanType) {
						this.$refs.sendNo.focus();
					}
					if (this.$refs.sysOrderNo && '2' == this.scanType) {
						this.$refs.sysOrderNo.focus();
					}
				})
			},
			initData() {
				if (!!this.filterData.sendNo || !!this.filterData.sysOrderNo) {
					//调用接口
					this.getPageData();
				} else {
					this.$message.warning(this.$t('i18nn_6b645127e97ecdf1'));
					return;
				}

			},
			goBack() {
				this.$router.go(-1);
			},

			clearFilter() {
				Object.getOwnPropertyNames(this.filterData).forEach(key => {
					this.filterData[key] = '';
				});

			},
			//保存选择的打印机
			changePrinter(v) {
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
			},

			//初始化打印机列表
			initPriterList() {
				let printerList = GetPrinterList();
				console.log('printerList', printerList);
				this.printerList = printerList;
			},

			//查看已打或未打
			// showPrintDet(){
			// 	this.ScanPrintDetOpenTime = new Date().getTime();
			// },

			//分页的筛选项数据
			pageFilterData() {
				// let scanType = ''; //1：sku  2：运单号
				// if ('second' == this.activeName) {
				// 	scanType = '2';
				// } else {
				// 	scanType = '1';
				// }
				return {
					sendNo: this.filterData.sendNo ? this.filterData.sendNo : null,
					sysOrderNo: this.filterData.sysOrderNo ? this.filterData.sysOrderNo : null,
					// "keyword": this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// "userId": this.printBatchInfo.userId ? this.printBatchInfo.userId : null,
					// "scanType": scanType
				};
			},
			//
			getPageData() {
				//防止扫码过快
				if (this.loading_load) {
					this.$message.warning("loading..");
					return;
				}
				let filterData = {};
				//如果 0==printStatus，把上次结果回传
				// if ('0' === this.currentData.printStatus) {
				// filterData = Object.assign(this.currentData, this.pageFilterData());
				// } else {
				filterData = this.pageFilterData();
				// }
				this.currentData = {};
				this.tableData = [];
				this.multipleSelection = [];
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhInShipmentsCommandPageList, filterData)
					.then(async ({
						data
					}) => {

						this.loading_load = false;
						if (200 == data.code && data.rows && data.rows.length == 1) {
							if (data.rows.length <= 0) {
								this.$message.warning(this.$t('i18nn_49356bf96d04d558'));
								// this.currentData = {};
								// this.multipleSelection = [];
								return;
							}
							let currentData = data.rows[0];

							this.currentData = currentData;
							
							//附件查询
							this.getListData(this.currentData.id);

						} else {
							let errorMsg = data.msg ? data.msg : this.$t('i18nn_33a114a8cba0232f');
							this.$message.warning(errorMsg);
						}
					})
					.catch(error => {
						console.log(error);
						let errorMsg = data.msg ? data.msg : '查询该批次失败！';
						this.$message.error(errorMsg);
						this.loading_load = false;
					});
			},
			
			//查询 /wh/bizWhFile/query/{relationId}
			getListData(relationId) {
				this.loading_load = true;
				let url = '';
				// if(this.isShowSelTypes){
				url = this.$urlConfig.WhFileUploadList + '/all/' + relationId;
				// } else {
				// 	url = this.$urlConfig.WhFileUploadList + '/' + this.relationId;
				// }
				this.$http
					.get(url, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							// if (data.rows) {
							let tableData_p = data.rows;
							let tableData = [];
							tableData_p.forEach(item=>{
								// tableData = tableData.concat(item.records);
								item.records && item.records.forEach(item2=>{
									item2.typeName = item.typeName;
									tableData.push(item2);
								})
							})
							
							this.tableData = tableData;
							
							this.$nextTick(() => {
								//附件全部勾选
								this.fileSelByTypeAll();
								if (this.isAuto) { //自动打印
									this.printerAction();
								}
							})
							
							// this.fileCountAll = this.tableData.reduce((prev, curr, idx, arr) => {
							// 	return prev + curr.records.length;
							// }, 0);
			
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading_load = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			
			//扫描成功
			// scanSuccessAction() {
			// 	this.scanList.unshift({
			// 		scanCode: this.filterData.goodsSku
			// 	});
			// 	this.filterData.goodsSku = "";
			// 	this.$nextTick(() => {
			// 		this.$refs.sku.focus();
			// 	})
			// },
			//调用打印
			// async autoPrint(data) {
			// 	let item = data;

			// 	let reData = {};
			// 	//扫描完成才可以打印
			// 	if (1 == item.printStatus) {
			// 		item.sku = data.goodsSku;
			// 		item.quantity = data.quantity;
			// 		let base64 = data.pdfBase64;
			// 		let printSeq = data.printSeq;
			// 		// reData = await priterPdfBase64('ScanPrint', item, base64, printSeq, null, this.printerVal, this
			// 		// 	.printerSize);
			// 	} else {
			// 		reData = item;
			// 	}
			// 	// this.countPdfLabelAction(item.pdfNum, item.quantity);
			// 	return reData;
			// },

			//选择全部附件
			fileSelByTypeAll() {
				let fileList = this.tableData;
				if (fileList <= 0) {
					this.$message.warning('该单号未查询到附件')
					return;
				}

				let files = fileList;
				//只有pdf和image可以打印
				files = files.filter(item => {
					let FileType = getFileType(item.url);
					item.FileType = FileType;
					return 'pdf' == FileType || 'image' == FileType;
				});

				console.log('fileSelAll1', files);

				//打印类型文件
				files = files.filter(item => {
					return this.selFileTypes.includes(item.type);
				});

				console.log('fileSelAll2', files);

				// this.$nextTick(() => {
				files.forEach(item => {
					this.$refs.multipleTable.toggleRowSelection(item, true);
				});
				// });
			},

			//选择文件类型
			handleCheckedFileTypesChange(value) {
				console.log(value);
				let fileList = this.tableData;
				if (fileList <= 0) {
					this.$message.warning('该单号未查询到附件')
					return;
				}

				let files = fileList;
				//只有pdf和image可以打印
				files = files.filter(item => {
					let FileType = getFileType(item.url);
					item.FileType = FileType;
					return 'pdf' == FileType || 'image' == FileType;
				});
				console.log('handleCheckedFileTypesChange 1', files);
				//打印类型文件
				files = files.filter(item => {
					return this.selFileTypes.includes(item.type);
				});
				console.log('handleCheckedFileTypesChange 2', this.selFileTypes, files);
				console.log('handleCheckedFileTypesChange 2', files);
				this.$nextTick(() => {
					//全部取消勾选
					fileList.forEach(item => {
						this.$refs.multipleTable.toggleRowSelection(item, false);
						// }
					});
					//勾选
					files.forEach(item => {
						this.$refs.multipleTable.toggleRowSelection(item, true);
						// }
					});
				});

			},

			//手动打印
			async printStart() {
				await this.printerAction();

			},
			//打印当前附件
			// printCurrenFile() {
			// 	let fileList = this.currentData.attachments;
			// 	//只有pdf和image可以打印
			// 	let files = fileList;
			// 	files = files.filter(item => {
			// 		let FileType = getFileType(item.url);
			// 		item.FileType = FileType;
			// 		return 'pdf' == FileType || 'image' == FileType;
			// 	});

			// 	//打印类型文件
			// 	files = files.filter(item => {
			// 		// let FileType = getFileType(item.url);
			// 		// item.FileType = FileType;
			// 		// return 'pdf' == FileType || 'image' == FileType;
			// 		return this.selFileTypes.includes(item.code);
			// 	});

			// 	// this.$nextTick(() => {
			// 	// 	files.forEach(item => {
			// 	// 		this.$refs.multipleTable.toggleRowSelection(item, true);
			// 	// 	});
			// 	// });
			// 	// type
			// },

			//打印
			async printerAction() {
				let fileList = this.multipleSelection;
				// console.log(this.tableData);
				if(fileList<=0){
					this.$message.warning('未勾选数据');
					return;
				}
				this.print_loading = true;
				let files = fileList;
				//只有pdf和image可以打印
				// files = files.filter(item => {
				// 	let FileType = getFileType(item.url);
				// 	item.FileType = FileType;
				// 	return 'pdf' == FileType || 'image' == FileType;
				// });
				//打印类型文件
				// files = files.filter(item => {
				// 	// let FileType = getFileType(item.url);
				// 	// item.FileType = FileType;
				// 	// return 'pdf' == FileType || 'image' == FileType;
				// 	return this.selFileTypes.includes(item.type);
				// });

				// this.$nextTick(() => {
				// 	// this.$refs.multipleTable.toggleAllSelection();
				// 	// fileList.forEach(item=>{
				// 	// 	let FileType = getFileType(item.url);
				// 	// 	if('pdf'==FileType || 'image'==FileType){
				// 	// 		this.$refs.multipleTable.toggleRowSelection(item,true);
				// 	// 	}
				// 	// });
				// 	files.forEach(item => {
				// 		// let FileType = getFileType(item.url);
				// 		// if('pdf'==FileType || 'image'==FileType){
				// 		this.$refs.multipleTable.toggleRowSelection(item, true);
				// 		// }
				// 	});
				// });

				// console.log('files', files);
				// if (files.length < 1) {
				// 	this.$message.warning(this.$t('i18nn_19ec51d5bd5fc832') + 'PDF' + this.$t('i18nn_c82aace5dd1ac3dd'));
				// 	this.print_loading = false;
				// 	return;
				// }

				for (let j = 0; j < files.length; j++) {
					console.log('for j:' + j);
					let item = files[j];
					
					//接口转换以及记录次数
					let res_data = await this.$http.get(this.$urlConfig.WhFileGetPrtById + '/' + item.id, {});
					if(res_data && res_data.data && 200==res_data.data.code){
						let prt_data = res_data.data.data;
						//打印次数
						for (let i = 0, ln = this.printNum; i < ln; i++) {
							await pritePdfOrImgUrl('file', (j + 1) + ':' + (i + 1), prt_data.url,prt_data.base64, item.FileType, this.printerVal)
								.then(res => {})
								.catch(err => {
									console.log(err);
									this.$message.warning(this.$t('i18nn_080a48f195815c62') + (j + 1) + ':' + (i + 1) + ';' + this.$t(
										'i18nn_9193c7e8137a5879'));
								});
						}
					} else {
						// this.$message.warning(this.$t('i18nn_080a48f195815c62') + (j + 1) + ':' + (i + 1) + ';' + this.$t(
						// 	'i18nn_9193c7e8137a5879')+'error:1');
						let msg = "";
						if(res_data && res_data.data && res_data.data.msg){
							 msg = (j + 1) + ';' +res_data.data.msg;
						} else {
							msg = this.$t('i18nn_080a48f195815c62') + (j + 1) + ';' + this.$t(
							'i18nn_9193c7e8137a5879')+'error:1';
						}
						this.$message.warning(msg);
						
					}
				}
				this.print_loading = false;
				
				if(this.isAuto){//自动
					this.clearsendNo();//清空
				}
				// this.filterData.sendNo = "";
				// this.filterData.sysOrderNo = "";
				// });
			},

			//重新打印
			// rePrint() {
			// 	// this.autoPrint(this.currentData);
			// 	this.printerAction(this.currentData.attachments)
			// },
			//发货编号基本信息
			getPrintInfoByBatchAction() {
				if (!!this.filterData.sendNo || !!this.filterData.sysOrderNo) {
					this.getPageData();
				} else {
					this.$message.warning(this.$t('i18nn_40a6f7ebeefeb8f9'));
				}

			},
			//查询批次数据
			// getPrintInfoByBatch() {
			// 	//防止扫码过快
			// 	if (this.loading_load) {
			// 		this.$message.warning("正在加载,请稍候");
			// 		return;
			// 	}
			// 	this.issendNoData = false;
			// 	let filterData = {
			// 		"sendNo": this.filterData.sendNo
			// 	}
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhDsScanPrintInfo, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;

			// 			if (200 == data.code && data.data) {
			// 				this.issendNoData = true;
			// 				this.printBatchInfo = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.sku.focus();
			// 				});

			// 			} else {
			// 				let errorMsg = data.msg ? data.msg : this.$t('i18nn_45ec4789fbc5c60f');
			// 				this.$message.warning(errorMsg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			let errorMsg = data.msg ? data.msg : '查询该批次信息失败！';
			// 			this.$message.error(errorMsg);
			// 		});
			// },
			//计算总面单数
			// countPdfLabelAction(pdfNum, skuQuantity) {
			// 	if (pdfNum) {
			// 		this.countPdfLabel = this.countPdfLabel + parseInt(pdfNum);
			// 	}
			// 	if (skuQuantity) {
			// 		this.skuQuantity = this.skuQuantity + parseInt(skuQuantity);
			// 	}
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.scanCon {
		display: flex;
		margin-top: 10px;
	}

	.scanLeft {
		width: 600px;
		// width: 1000px;
		// flex:1;
		margin: 0 10px;
	}

	.scanRight {
		// width: 300px;
		min-width: 1000px;
		max-width: 2000px;
		flex: 1;

		.scanRiCon {
			overflow: auto;
		}
	}

	// .scanCodeList {
	// 	li {
	// 		margin: 10px 0;
	// 	}
	// }

	// .scanCard {
	// 	position: relative;
	// 	.scanTips {
	// 		position: absolute;
	// 		right:0;
	// 		top:0;

	// 	}
	// }

	// .scanList {
	// 	// padding: 10px;
	// 	// overflow: auto;

	// 	.scanItem {
	// 		margin-bottom: 10px;

	// 		.scanLine {
	// 			margin: 10px;
	// 			display: flex;

	// 			// justify-content: space-between;
	// 			.scanLineData {
	// 				padding: 0 10px;
	// 				;
	// 			}
	// 		}
	// 	}
	// }

	// .descRecLabel {
	// 	// width: 300px;
	// 	// text-align: right;
	// 	// color:red;
	// 	background: #fff;
	// }
	// .scanInfoContent {
	// 	display: flex;
	// 	.scanInfoLeft {

	// 	}
	// 	.scanInfoRight {

	// 	}
	// }
</style>